import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class DewirideRAG extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      pricingTableId: null,
      publishableKey: null,
      clientSecret: null,
    };
    this.openModal = this.openModal.bind(this);
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const pricingTableId = urlParams.get('pricingTableId');
    const publishableKey = urlParams.get('publishableKey');
    const clientSecret = urlParams.get('clientSecret');

    if (pricingTableId && publishableKey && clientSecret) {
      this.setState({
        pricingTableId,
        publishableKey,
        clientSecret,
      });
    }
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    const { pricingTableId, publishableKey, clientSecret } = this.state;

    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Dewiride RAG" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Dewiride RAG</h2>
                  <p>One place solution for your RAG.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {pricingTableId && publishableKey && clientSecret && (
                      <stripe-pricing-table
                        pricing-table-id={pricingTableId}
                        publishable-key={publishableKey}
                        customer-session-client-secret={clientSecret}
                      ></stripe-pricing-table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default DewirideRAG;
